import { Link } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import "./header.css"

const Header = () => {
  return (
    <header>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
          integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4966200876419202"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <Link to="/" className="logo">
        Coding<span style={{ color: "#9962ffcd" }}>G</span>yan
      </Link>
      <input type="checkbox" id="menuBtn" />
      <nav>
        <Link to="/about" className="link">
          About
        </Link>
        <Link to="/privacy" className="link">
          Privacy Page
        </Link>
        <Link to="/terms" className="link">
          Terms
        </Link>
        <Link to="/blogs" className="link">
          Source Codes
        </Link>
        <label htmlFor="menuBtn" className="close_btn">
          Close
        </label>
      </nav>
    </header>
  )
}

export default Header
