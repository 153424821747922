import React from "react"
import "./footer.css"

const Footer = () => {
  return (
    <footer>
      <div className="links">
        <a href="https://www.instagram.com/coding_gyan_/" target="_blank">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <a href="https://github.com/shubham44-code" target="_blank">
          <i class="fa-brands fa-github"></i>
        </a>
        <a href="https://twitter.com/Shubham_Sr44" target="_blank">
          <i class="fa-brands fa-twitter"></i>
        </a>
        <a href="https://www.linkedin.com/in/shubhamraut44/" target="_blank">
          <i class="fa-brands fa-linkedin"></i>
        </a>
      </div>
      <p>Made with 💜 by Shubham Raut.</p>
    </footer>
  )
}

export default Footer
